section.contacts-page {
  position: relative;
  padding-bottom: 150px;
  .container {
    display: grid;
    grid-template-areas: 'title title' 'info form' 'map map';
    grid-template-columns: 640px calc(100% - 640px);
    grid-auto-rows: auto;
    grid-row-gap: 10px;
    @media (max-width: 1200px) {
      grid-template-columns: 540px calc(100% - 540px); }
    @media (max-width: 767px) {
      grid-template-areas: 'title' 'info' 'map' 'form';
      grid-template-columns: 1fr;
      grid-row-gap: 0; } }
  &:before {
    grid-area: title;
    content: '';
    width: 100vw;
    height: 1245px;
    position: absolute;
    top: -35px;
    left: 0;
    background: url("../img/contacts_BG.png") center center / cover no-repeat;
    opacity: .1;
    z-index: -1;
    max-height: calc(100% + 35px); }
  .section-title {
    font-weight: 800;
    font-size: 45px;
    line-height: 60px;
    text-transform: uppercase;
    color: $black;
    display: flex;
    margin: 0 0 50px; }
  .info {
    grid-area: info;
    max-width: 640px;
    width: 100%;
    background: #35688C;
    padding: 35px 50px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    margin-bottom: 40px;
    @media (max-width: 767px) {
      margin-bottom: 0;
      margin-right: 10px;
      margin-left: -15px; }
    @media (max-width: 576px) {
      width: 100%;
      padding: 35px 15px 30px;
      box-sizing: border-box; }
    &:after {
      content: '';
      width: 220px;
      height: 275px;
      background: url("../img/svg/mask_blue.svg") center center / cover no-repeat;
      position: absolute;
      bottom: 75px;
      right: 25px;
      z-index: 0; }
    .block {
      display: flex;
      flex-direction: column;
      box-shadow: 0 0 4px #1B5278;
      padding: 0 25px 0 16px;
      width: 360px;
      box-sizing: border-box;
      margin-bottom: 20px;
      z-index: 1;
      background: #35688C;
      position: relative;
      transition: padding .3s;
      @media (max-width: 576px) {
        width: 100%; }
      &:last-child {
        margin-bottom: 0; }
      &.active {
        padding: 24px 25px 30px 16px;
        .title {
          color: #ffffff; } }
      .title {
        font-weight: 800;
        font-size: 20px;
        line-height: 34px;
        color: #95BFDD;
        margin: 0;
        cursor: pointer; }
      .wrapper {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        transition: height .3s;
        font-weight: 800;
        font-size: 15px;
        line-height: 20px;
        height: 0;
        color: #ffffff;
        position: relative;
        .address {
          margin: 0 0 20px;
          max-width: 240px; }
        .phones {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin: 0 0 18px;
          a {
            text-decoration: none;
            color: inherit;
            margin: 0 0 5px;
            &:last-child {
              margin-bottom: 0; } } }
        .email {
          margin: 0 0 25px; }
        .skype {
          margin: 0 0 25px; }
        .work-time {
          display: flex;
          flex-direction: column;
          p {
            margin: 0; }
          span {
            font-weight: 400; } }
        .social {
          position: absolute;
          display: flex;
          align-items: center;
          right: 0;
          bottom: 0;
          @media (max-width: 576px) {
            position: static;
            margin-top: 17px; }
          a {
            width: 25px;
            height: 25px;
            margin-right: 15px;
            &:last-child {
              margin-right: 0; }
            &.facebook {
              background: url("../img/svg/facebook_blue.svg") center center / 100% 100% no-repeat; }
            &.instagram {
              background: url("../img/svg/instagram_blue.svg") center center / 100% 100% no-repeat; }
            &.telegram {
              background: url("../img/svg/telegram_blue.svg") center center / 100% 100% no-repeat; } } } } } }
  .form {
    grid-area: form;
    margin-top: 55px;
    margin-left: -155px;
    box-sizing: border-box;
    padding: 72px 70px;
    background: #F8F8F8;
    position: relative;
    @media (max-width: 1200px) {
      margin-left: -105px;
      padding: 50px 30px; }
    @media (max-width: 890px) {
      height: max-content;
      padding: 35px 30px 35px 35px;
      max-width: 550px; }
    @media (max-width: 767px) {
      margin: -20px 0 0; }
    @media (max-width: 576px) {
      width: auto;
      max-width: none;
      padding: 50px 5px 30px; }
    &:before {
      content: '';
      width: 335px;
      height: 220px;
      position: absolute;
      right: -70px;
      bottom: -35px;
      background: url("../img/svg/mask.svg") center center / 100% 100% no-repeat;
      z-index: -1; }
    .title {
      font-weight: 800;
      font-size: 23px;
      line-height: 60px;
      text-transform: uppercase;
      color: $black;
      text-align: center;
      margin: 0 0 10px;
      @media (max-width: 890px) {
        font-size: 18px;
        line-height: 24px; } }
    .desc {
      font-size: 15px;
      line-height: 22px;
      color: $black;
      text-align: center;
      margin: 0 0 38px; }
    form {
      display: flex;
      align-items: center;
      @media (max-width: 767px) {
        flex-direction: column; }
      .fields {
        display: flex;
        flex-direction: column;
        max-width: 300px;
        margin-right: 75px;
        @media (max-width: 890px) {
          max-width: 350px;
          margin-right: 25px; }
        @media (max-width: 767px) {
          margin-right: 0;
          max-width: none;
          width: 100%; }
        label:not(.checkbox) {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-bottom: 15px;
          input {
            width: 100%;
            border: solid #575767;
            border-width: 0 0 1px;
            padding: 5px 0 5px 15px;
            box-sizing: border-box;
            font-size: 12px;
            line-height: 14px;
            color: $black;
            background: transparent;
            &::placeholder {
              color: #80808a; } } }
        .checkbox {
          display: block;
          position: relative;
          padding-left: 25px;
          cursor: pointer;
          font-size: 10px;
          line-height: 11px;
          user-select: none;
          a {
            color: inherit; }
          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            &:checked ~ span:after {
              content: ''; } }
          span {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            margin: auto;
            height: 14px;
            width: 14px;
            box-sizing: border-box;
            border: 1px solid #575757;
            &:after {
              content: none;
              position: absolute;
              top: 0;
              right: 0;
              bottom: 1px;
              left: 1px;
              margin: auto;
              width: 4px;
              height: 7px;
              border: solid #575757;
              border-width: 0 1px 1px 0;
              transform: rotate(45deg); } } } }
      .btn {
        padding-left: 61px;
        font-size: 15px;
        line-height: 18px;
        max-width: 113px;
        @media (max-width: 890px) {
          padding-left: 20px; }
        @media (max-width: 767px) {
          padding-left: 15px;
          max-width: 90px;
          margin-top: 45px;
          align-self: flex-end; }
        &:after {
          width: 125px;
          height: 125px;
          @media (max-width: 890px) {
            width: 70px;
            height: 70px; } }
        &:before {
          width: 46px;
          height: 46px;
          @media (max-width: 890px) {
            width: 23px;
            height: 23px; } } } } }
  .maps {
    grid-area: map;
    display: flex;
    flex-direction: column;
    @media (max-width: 767px) {
      width: calc(100% + 30px);
      margin-left: -15px; }
    .map {
      width: 100%;
      height: 300px;
      display: none;
      &.active {
        display: flex; }
      iframe {
        width: 100%;
        height: 100%; } } } }
