section.service-title {
  display: flex;
  flex-direction: column;
  margin-bottom: 240px;
  z-index: 1;
  @media (max-width: 920px) {
    margin-bottom: 65px; }
  .section-title {
    font-size: 45px;
    line-height: 60px;
    color: $black;
    text-transform: uppercase;
    margin: 0 0 84px;
    @media (max-width: 767px) {
      font-size: 28px;
      line-height: 31px;
      margin-bottom: 18px; }
    @media (max-width: 576px) {
      font-size: 23px;
      line-height: 28px; }
    span {
      font-weight: 800;
      display: block; } }
  .info {
    position: relative;
    display: flex;
    justify-content: flex-start;
    @media (max-width: 920px) {
      flex-direction: column; }
    .text {
      display: flex;
      flex-direction: column;
      padding: 54px;
      max-width: 660px;
      box-sizing: border-box;
      background: rgba(248, 248, 248, 0.95);
      @media (max-width: 576px) {
        padding: 35px 25px 40px;
        margin-right: -15px;
        margin-left: -15px; }
      .name {
        font-size: 30px;
        line-height: 38px;
        text-transform: uppercase;
        color: $black;
        margin: 0 0 30px;
        @media (max-width: 576px) {
          font-size: 18px;
          line-height: 22px; }
        span {
          display: block;
          font-weight: 800; } }
      .desc {
        display: flex;
        flex-direction: column;
        &>* {
          margin: 0 0 10px;
          &:last-child {
            margin-bottom: 0; } }
        p {
          font-size: 15px;
          line-height: 22px;
          color: $black; } } }
    .image {
      position: absolute;
      top: -37px;
      right: 0;
      bottom: 0;
      margin: auto;
      z-index: -1;
      @media (max-width: 920px) {
        position: static;
        margin-top: -120px; }
      @media (max-width: 767px) {
        margin-top: 0;
        margin-right: -15px; } } } }
