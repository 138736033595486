section.post {
  display: flex;
  flex-direction: column;
  &>.image {
    width: calc(100% - 85px);
    height: auto;
    margin-left: 85px;
    @media (max-width: 767px) {
      width: calc(100% + 30px);
      margin-left: -15px; } }
  .actual-news {
    float: right;
    position: relative;
    background: #ffffff;
    width: max-content;
    margin-right: -25px;
    padding: 95px 0 50px 20px;
    margin-left: 60px;
    margin-bottom: 25px;
    z-index: 2;
    &:before {
      content: '';
      width: 100%;
      height: 60px;
      position: absolute;
      bottom: 100%;
      background: #ffffff;
      left: 0; }
    &:after {
      content: none; }
    @media (max-width: 1200px) {
      padding-top: 30px;
      width: 470px; }
    @media (max-width: 930px) {
      margin-left: 20px; }
    @media (max-width: 870px) {
      width: 390px; }
    @media (max-width: 767px) {
      order: 1;
      float: none;
      margin: 0 0 50px;
      width: 100%;
      padding: 35px 5px 40px;
      &:before {
        content: none; } } }
  .wrapper {
    padding: 60px 25px 35px 55px;
    background: rgba(248, 248, 248, 0.95);
    margin-top: -70px;
    margin-bottom: 210px;
    @media (max-width: 930px) {
      padding: 60px 15px 35px 15px; }
    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
      margin-bottom: 70px; }
    .text {
      margin-bottom: 30px;
      &>* {
        margin: 0 0 20px;
        &:last-child {
          margin-bottom: 0; } }
      p {
        font-size: 15px;
        line-height: 22px;
        color: $black;
        @media (max-width: 576px) {
          font-size: 13px; } }
      .title {
        font-size: 23px;
        line-height: 32px;
        text-transform: uppercase;
        color: $black;
        margin-bottom: 30px;
        span {
          font-weight: 800;
          display: block; }
        @media (max-width: 576px) {
          font-size: 16px;
          line-height: 17px; } }
      .annotation {
        margin-left: 95px;
        background: #ffffff;
        position: relative;
        z-index: 1;
        box-sizing: border-box;
        border-left: 3px solid #35688c;
        padding: 24px 80px 24px 40px;
        font-weight: 600;
        font-size: 15px;
        line-height: 22px;
        color: #35688c;
        @media (max-width: 767px) {
          margin-left: 20px;
          padding-right: 40px; }
        @media (max-width: 576px) {
          font-size: 13px; }
        @media (max-width: 480px) {
          margin-left: 0;
          padding: 12px 10px; } } }
    .info {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .date {
        font-size: 12px;
        line-height: 22px;
        color: #9e9e9e;
        margin: 0 9px 0 0;
        @media (max-width: 480px) {
          font-size: 11px; } }
      .views {
        padding-left: 20px;
        position: relative;
        font-size: 12px;
        line-height: 22px;
        color: #9e9e9e;
        margin: 0;
        @media (max-width: 480px) {
          font-size: 11px; }
        &:before {
          content: '';
          width: 14px;
          height: 14px;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          margin: auto;
          background: url("../img/svg/views.svg") center center / 100% 100% no-repeat; } } } }
  .you-may-interest {
    display: flex;
    flex-direction: column;
    margin-bottom: 150px;
    @media (max-width: 767px) {
      margin-bottom: 70px; }
    .title {
      font-weight: 800;
      font-size: 45px;
      line-height: 60px;
      text-transform: uppercase;
      color: $black;
      margin: 0 0 57px;
      @media (max-width: 767px) {
        font-size: 23px;
        line-height: 28px;
        margin-bottom: 25px;
        span {
          font-weight: 400; } } }
    .slider {
      width: 100%;
      margin-bottom: 40px;
      .elem {
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
        .image {
          position: relative;
          margin-right: 70px;
          @media (max-width: 767px) {
            margin-right: 12px; } }
        .text {
          display: flex;
          flex-direction: column;
          margin-top: -60px;
          background: rgba(248, 248, 248, 0.95);
          padding: 30px 24px 20px 45px;
          box-sizing: border-box;
          margin-left: 25px;
          position: relative;
          z-index: 2;
          @media (max-width: 940px) {
            padding: 15px 12px 10px 25px;
            margin-left: 10px;
            margin-right: 10px; }
          @media (max-width: 767px) {
            margin-left: 12px;
            margin-right: 0; }
          @media (max-width: 480px) {
            padding: 25px 5px 20px 15px; }
          .title {
            font-size: 23px;
            line-height: 32px;
            text-transform: uppercase;
            color: $black;
            display: flex;
            flex-direction: column;
            margin: 0 0 10px;
            text-decoration: none;
            @media (max-width: 970px) {
              font-size: 21px;
              line-height: 28px; }
            @media (max-width: 830px) {
              font-size: 18px;
              line-height: 24px; }
            @media (max-width: 767px) {
              font-size: 23px;
              line-height: 32px; }
            @media (max-width: 480px) {
              font-size: 18px;
              line-height: 22px;
              margin-bottom: 5px; }
            span {
              font-weight: 800;
              display: block; } }
          .desc {
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;
            max-height: 70px;
            overflow: hidden;
            position: relative;
            @media (max-width: 480px) {
              margin-bottom: 5px; }
            &:before {
              content: '';
              width: 100%;
              height: 100%;
              user-select: none;
              pointer-events: none;
              position: absolute;
              bottom: 0;
              left: 0;
              background: linear-gradient(180deg,rgba(50, 50, 50, 0) 0%,#f8f8f8 100%); }
            &>* {
              margin: 0 0 5px;
              &:last-child {
                margin-bottom: 0; } }
            p {
              font-size: 15px;
              line-height: 22px;
              color: $black;
              @media (max-width: 480px) {
                font-size: 13px;
                line-height: 19px; } } }
          .user-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .left-side {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              .date {
                font-size: 12px;
                line-height: 22px;
                color: #9e9e9e;
                margin: 0 9px 0 0;
                @media (max-width: 480px) {
                  font-size: 11px; } }
              .views {
                padding-left: 20px;
                position: relative;
                font-size: 12px;
                line-height: 22px;
                color: #9e9e9e;
                margin: 0;
                @media (max-width: 480px) {
                  font-size: 11px; }
                &:before {
                  content: '';
                  width: 14px;
                  height: 14px;
                  position: absolute;
                  top: 0;
                  left: 0;
                  bottom: 0;
                  margin: auto;
                  background: url("../img/svg/views.svg") center center / 100% 100% no-repeat; } } }
            a {
              font-size: 15px;
              line-height: 18px;
              color: $black;
              margin: 0;
              padding-left: 35px;
              position: relative;
              text-decoration: none;
              @media (max-width: 480px) {
                font-size: 12px; }
              &:before {
                content: '';
                width: 25px;
                height: 25px;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                margin: auto;
                background: url("../img/svg/right-arrow.svg") center center / 100% 100% no-repeat; } } } } } }
    .arrows {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .arrow {
        width: 13px;
        height: 25px;
        background: url("../img/svg/slider-arrow.svg") center center / 100% 100% no-repeat;
        cursor: pointer;
        margin-right: 25px;
        outline: none;
        &:last-child {
          margin-right: 0; }
        &.prev {
          transform: rotate(180deg); } } } } }
