section.our-team {
  display: flex;
  flex-direction: column;
  margin-bottom: 215px;
  @media (max-width: 1365px) {
    margin-bottom: 155px; }
  @media (max-width: 767px) {
    margin-bottom: 60px; }
  &.container {
    @media (min-width: 1366px) {
      max-width: 1095px; } }
  .section-title {
    font-size: 45px;
    line-height: 60px;
    color: $black;
    text-transform: uppercase;
    margin: 0 0 65px;
    span {
      font-weight: 800; }
    @media (max-width: 576px) {
      font-size: 23px;
      line-height: 28px;
      margin-bottom: 30px; } }
  .team-slider {
    width: 100%;
    .slide {
      display: flex;
      justify-content: space-between;
      position: relative;
      width: 100%;
      @media (max-width: 767px) {
        flex-direction: column; }
      .text {
        display: flex;
        flex-direction: column;
        max-width: 530px;
        @media (max-width: 767px) {
          position: relative; }
        .name {
          display: flex;
          flex-direction: column;
          font-size: 30px;
          line-height: 37px;
          color: $black;
          text-transform: uppercase;
          margin: 0 0 16px;
          span {
            width: 100%;
            font-weight: 800; }
          @media (max-width: 576px) {
            font-size: 13px;
            line-height: 19px; } }
        .desc {
          font-size: 15px;
          line-height: 22px;
          color: $black;
          margin: 0 0 50px;
          @media (max-width: 767px) {
            margin: 0; }
          @media (max-width: 576px) {
            font-size: 13px;
            line-height: 19px; } }
        .info {
          display: flex;
          flex-direction: column;
          align-items: center;
          @media (max-width: 767px) {
            margin: 0;
            position: absolute;
            top: calc(100% + 40px);
            left: 5px; }
          span {
            font-weight: 800;
            font-size: 100px;
            line-height: 100px;
            color: $main-color;
            text-transform: uppercase;
            margin: 0 0 15px;
            @media (max-width: 480px) {
              font-size: 60px;
              line-height: 60px; } }
          p {
            margin: 0;
            font-weight: 800;
            font-size: 23px;
            line-height: 32px;
            color: $black;
            text-transform: uppercase;
            text-align: center;
            @media (max-width: 480px) {
              font-size: 13px;
              line-height: 17px; } } } }
      .image {
        width: 0;
        @media (max-width: 767px) {
          width: auto;
          align-self: flex-end; }
        @media (max-width: 576px) {
          max-width: calc(100% - 130px); }
        img {
          position: absolute;
          right: 0;
          bottom: 0;
          width: auto;
          height: auto;
          max-height: 100%;
          max-width: calc(100% - 550px);
          @media (max-width: 767px) {
            position: static;
            max-height: 270px;
            max-width: 100%; } } } }
    .arrows {
      position: absolute;
      left: 530px;
      top: 42px;
      margin: auto;
      display: flex;
      z-index: 1;
      @media (max-width: 940px) {
        top: 0; }
      @media (max-width: 767px) {
        right: 0;
        left: auto; }
      .arrow {
        width: 13px;
        height: 25px;
        cursor: pointer;
        margin-right: 25px;
        background: url("../img/svg/slider-arrow.svg") center center / 100% 100% no-repeat;
        &:last-child {
          margin-right: 0; }
        &.prev {
          transform: rotate(180deg); } } } } }
