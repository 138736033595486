// OpenSans

@font-face {
  font-family: 'OpenSans';
  src: url('../../fonts/OpenSans/OpenSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'OpenSans';
  src: url('../../fonts/OpenSans/OpenSans-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: 'OpenSans';
  src: url('../../fonts/OpenSans/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'OpenSans';
  src: url('../../fonts/OpenSans/OpenSans-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'OpenSans';
  src: url('../../fonts/OpenSans/OpenSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'OpenSans';
  src: url('../../fonts/OpenSans/OpenSans-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic; }

@font-face {
  font-family: 'OpenSans';
  src: url('../../fonts/OpenSans/OpenSans-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'OpenSans';
  src: url('../../fonts/OpenSans/OpenSans-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic; }


// Open Sans
