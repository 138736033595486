footer {
  padding-top: 50px;
  padding-bottom: 15px;
  background: #F0F0F2;
  .container {
    display: flex;
    flex-direction: column;
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;
      @media (max-width: 767px) {
        display: grid;
        grid-template-areas: 'logo logo' 'menu social' 'menu social';
        padding-bottom: 10px;
        position: relative;
        width: 100%;
        &:after {
          content: '';
          width: 100%;
          height: 1px;
          background: #B3B3B8;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto; } }
      @media (max-width: 576px) {
        grid-template-areas: 'logo logo' 'menu menu'; }
      &>.logo {
        width: 280px;
        @media (max-width: 1365px) {
          width: 250px; }
        @media (max-width: 920px) {
          width: 150px;
          align-self: flex-start; }
        @media (max-width: 767px) {
          grid-area: logo;
          margin-bottom: 20px; } }
      .menu {
        flex: 1;
        margin: 0 5px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        @media (max-width: 767px) {
          grid-area: menu; }
        @media (max-width: 576px) {
          flex-direction: column;
          margin: 0; }
        .list {
          margin-right: 150px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          @media (max-width: 1200px) {
            margin-right: 75px; }
          @media (max-width: 767px) {
            margin-right: 30px; }
          @media (max-width: 576px) {
            margin-right: 0; }
          &:last-child {
            margin-right: 0; }
          .name {
            font-size: 15px;
            line-height: 30px;
            color: $main-color;
            border-bottom: 1px solid #212134;
            width: auto;
            margin: 0 0 10px; }
          ul {
            padding: 0;
            margin: 0;
            list-style: none;
            display: flex;
            flex-direction: column;
            li {
              font-size: 12px;
              line-height: 14px;
              margin: 0 0 5px;
              color: $black;
              &:last-child {
                margin-bottom: 0; }
              a {
                color: inherit;
                text-decoration: inherit; } } } } }
      .info {
        display: flex;
        flex-direction: column;
        @media (max-width: 767px) {
          grid-area: social;
          margin-left: 30px; }
        @media (max-width: 576px) {
          grid-area: menu;
          position: absolute;
          top: 0;
          right: 0; }
        .phones {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          margin-bottom: 32px;
          .phone {
            font-size: 15px;
            line-height: 20px;
            text-transform: uppercase;
            text-decoration: none;
            color: #323232;
            margin: 0; }
          .call {
            font-size: 12px;
            line-height: 20px;
            color: $main-color;
            text-decoration: underline; } }
        .social {
          display: flex;
          align-items: center;
          justify-content: center;
          a {
            width: 20px;
            height: 20px;
            margin-right: 15px;
            &:last-child {
              margin-right: 0; } } } } }
    .bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start; }
      .politic {
        display: flex;
        flex-direction: column;
        @media (max-width: 767px) {
          padding-bottom: 15px;
          border-bottom: 1px solid #b3b3b8;
          margin-bottom: 5px;
          width: 100%; }
        p {
          font-size: 11px;
          line-height: 15px;
          color: #373750;
          margin: 0;
          a {
            color: inherit; } } }
      .develop {
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 767px) {
          align-self: flex-end; }
        p {
          font-size: 12px;
          line-height: 15px;
          color: #44445f;
          margin: 0 9px 0 0; }
        .image {
          margin-right: 8px;
          &:last-child {
            margin-right: 0; }
          &.space {
            width: 25px;
            height: 14px; }
          &.site-ok {
            width: 31px;
            height: 13px; } } } } } }
