section.why-we {
  position: relative;
  margin-bottom: 240px;
  @media (max-width: 1365px) {
    margin-bottom: 140px; }
  @media (max-width: 767px) {
    margin-bottom: 40px; }
  &:before {
    content: '';
    position: absolute;
    top: -305px;
    left: 0;
    width: 1050px;
    height: 1270px;
    background: #f8f8f8;
    z-index: -1;
    @media (max-width: 1365px) {
      width: 50vw; }
    @media (max-width: 767px) {
      content: none; } }
  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .section-title {
      font-size: 45px;
      line-height: 60px;
      color: $black;
      text-transform: uppercase;
      margin: 0;
      span {
        font-weight: 800; }
      @media (max-width: 767px) {
        background: #f8f8f8;
        margin: 0 -15px;
        padding: 0 15px; }
      @media (max-width: 576px) {
        font-size: 23px;
        line-height: 28px;
        padding-bottom: 14px;
        span {
          display: block; } } }
    .flex {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      @media (max-width: 767px) {
        flex-direction: column; }
      .left-side {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        max-width: 630px;
        @media (max-width: 1365px) {
          max-width: 450px; }
        @media (max-width: 930px) {
          max-width: calc(50% - 15px); }
        @media (max-width: 767px) {
          max-width: none;
          margin: 0 -15px 25px;
          padding: 0 15px;
          background: #f8f8f8; }
        .text {
          font-size: 15px;
          line-height: 22px;
          color: $black;
          margin: 0 0 80px;
          @media (max-width: 767px) {
            margin-bottom: 37px; }
          @media (max-width: 576px) {
            font-size: 13px;
            line-height: 19px; } }
        .features {
          display: flex;
          flex-direction: column;
          margin-bottom: 120px;
          @media (max-width: 767px) {
            margin-bottom: 45px; }
          .feature {
            display: flex;
            align-items: center;
            margin-bottom: 35px;
            @media (max-width: 576px) {
              margin-bottom: 25px; }
            &:last-child {
              margin-bottom: 0; }
            .image {
              width: 64px;
              height: 64px;
              margin-right: 16px;
              @media (max-width: 576px) {
                width: 52px;
                height: 52px;
                margin-right: 14px; } }
            p {
              width: calc(100% - 80px);
              margin: 0;
              font-size: 15px;
              line-height: 22px;
              color: $black;
              @media (max-width: 576px) {
                font-size: 13px;
                line-height: 19px; } } } }
        .title {
          margin: 0;
          font-weight: 800;
          font-size: 23px;
          line-height: 32px;
          text-transform: uppercase;
          color: #35688c;
          @media (max-width: 930px) {
            font-size: 18px;
            line-height: 24px; }
          @media (max-width: 767px) {
            display: none; } } }
      .right-side {
        display: flex;
        flex-direction: column;
        max-width: 440px;
        width: 100%;
        @media (max-width: 930px) {
          max-width: calc(50% - 15px); }
        @media (max-width: 767px) {
          max-width: none; }
        .elem {
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;
          margin-bottom: 12px;
          &:last-child {
            margin-bottom: 0;
            &:after {
              content: none; } }
          &:after {
            content: '';
            width: 100%;
            height: 1px;
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            margin: auto;
            background: #B3B3B8; }
          .num {
            display: flex;
            position: relative;
            align-items: center;
            p {
              font-weight: 800;
              font-size: 50px;
              line-height: 60px;
              text-transform: uppercase;
              margin: 0;
              color: $main-color;
              @media (max-width: 576px) {
                font-size: 40px; } }
            &>span {
              position: absolute;
              top: 85%;
              right: -30px;
              font-size: 12px;
              line-height: 14px;
              text-transform: uppercase;
              color: $main-color;
              font-weight: 800; } }
          .text {
            font-weight: 800;
            font-size: 23px;
            line-height: 60px;
            text-transform: uppercase;
            color: $black;
            margin: 0;
            text-align: center;
            @media (max-width: 930px) {
              font-size: 21px;
              line-height: 36px; }
            @media (max-width: 576px) {
              font-size: 15px; } } } } } } }
