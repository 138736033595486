header {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  padding-bottom: 12px;
  @media (max-width: 767px) {
    display: grid;
    grid-template-areas: 'logo logo btn' 'nav phone btn';
    margin-bottom: 20px; }
  .logo {
    width: 153px;
    height: auto;
    @media (max-width: 767px) {
      grid-area: logo;
      margin-bottom: 15px; }
    @media (max-width: 576px) {
      width: 135px; } }
  nav {
    display: flex;
    align-items: center;
    flex: 1;
    @media (max-width: 900px) {
      position: relative;
      align-self: center; }
    @media (max-width: 767px) {
      grid-area: nav; }
    .burger {
      display: none;
      width: 33px;
      height: 14px;
      background: url("../img/svg/burger.svg") center center / 100% 100% no-repeat;
      cursor: pointer;
      margin-left: 15px;
      @media (max-width: 900px) {
        display: block; }
      @media (max-width: 767px) {
        margin-left: 0;
        margin-right: 15px; } } }
  .mobile-menu {
    flex: 1;
    display: flex;
    @media (max-width: 900px) {
      display: none;
      position: absolute;
      top: 100%;
      left: 15px;
      background: #E7E7E7;
      z-index: 10;
      padding: 12px 0;
      box-sizing: content-box;
      flex-direction: column;
      &.active {
        display: flex; }
      .links {
        flex-direction: column; } }
    @media (max-width: 767px) {
      left: 0;
      top: calc(100% + 5px); }
    .langs {
      display: none;
      padding: 10px 17px 0;
      border-top: 1px solid #C7C7C7;
      a {
        margin-right: 15px;
        font-size: 13px;
        line-height: 18px;
        text-transform: uppercase;
        color: $black;
        text-decoration: none;
        &.active {
          font-weight: 700;
          color: $main-color; }
        &:last-child {
          margin-right: 0; } }
      @media (max-width: 900px) {
        display: flex; } } }
  .links {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0 5px;
    list-style: none;
    @media (max-width: 900px) {
      padding-bottom: 5px;
      margin: 0;
      align-items: flex-start; }
    &>li {
      font-size: 15px;
      line-height: 20px;
      color: $black;
      position: relative;
      padding: 0;
      margin: 0 15px 0 0;
      @media (max-width: 900px) {
        position: static;
        margin: 0 0 5px;
        padding: 0 17px;
        box-sizing: border-box;
        width: auto; }
      &.active {
        font-weight: 700;
        color: $main-color;
        &:after {
          content: '';
          width: 100%;
          height: 3px;
          background: $main-color;
          position: absolute;
          bottom: -24px;
          z-index: 5;
          left: 0;
          right: 0;
          margin: auto;
          @media (max-width: 900px) {
            content: none;
            bottom: auto;
            top: 100%;
            width: calc(100% - 34px);
            left: 0;
            right: 0; } } }
      &:last-child {
        margin-right: 0;
        @media (max-width: 900px) {
          margin-bottom: 0; } }
      &>a {
        color: inherit;
        text-decoration: inherit;
        padding-bottom: 25px;
        @media (max-width: 900px) {
          width: 100%;
          padding: 0; } }
      &.has-child {
        &:hover {
          ul {
            display: flex;
            @media (max-width: 767px) {
              max-width: calc(100vw - (100% + 30px));
              padding: 15px 10px; } } }
        ul {
          display: none;
          position: absolute;
          z-index: 5;
          top: calc(100% + 23px);
          width: max-content;
          padding: 19px 22px;
          background: #EEEEEE;
          box-sizing: border-box;
          flex-direction: column;
          margin: 0;
          list-style: none;
          @media (max-width: 900px) {
            text-align: left;
            top: 0;
            left: 100%; }
          li {
            font-size: 15px;
            line-height: 20px;
            font-weight: 400;
            color: $black;
            margin: 0 0 5px;
            &:hover {
              color: $main-color;
              text-decoration: underline; }
            &:last-child {
              margin-bottom: 0; } }
          a {
            color: inherit;
            text-decoration: inherit; } } } } }
  .user-menu {
    display: flex;
    align-items: center;
    margin-right: 110px;
    @media (max-width: 1365px) {
      margin-right: 40px; }
    @media (max-width: 767px) {
      grid-area: phone;
      margin-right: 15px; }
    .language {
      display: flex;
      position: relative;
      font-size: 15px;
      line-height: 20px;
      text-transform: uppercase;
      color: $black;
      text-decoration: none;
      margin: 0 40px 0 0;
      @media (max-width: 955px) {
        margin-right: 20px; }
      @media (max-width: 900px) {
        display: none; }
      &:hover {
        ul {
          display: flex; } }
      .current {
        font-weight: 700;
        color: $main-color; }
      ul {
        display: none;
        position: absolute;
        margin: 0;
        left: -5px;
        top: 100%;
        flex-direction: column;
        background: #ffffff;
        z-index: 5;
        padding: 5px; }
      a {
        text-decoration: inherit;
        color: inherit; } }
    .phones {
      position: relative;
      font-size: 15px;
      line-height: 20px;
      color: $black;
      &:hover ul {
        display: block; }
      a {
        text-decoration: none;
        color: inherit; }
      .main-phone {
        display: flex;
        position: relative;
        padding-right: 15px;
        &:after {
          content: '';
          width: 7px;
          height: 7px;
          border: solid $black;
          border-width: 0 1px 1px 0;
          position: absolute;
          top: -4px;
          bottom: 0;
          right: 0;
          margin: auto;
          transform: rotate(45deg); } }
      ul {
        margin: 0;
        display: none;
        list-style: none;
        position: absolute;
        top: 100%;
        padding: 10px 14px;
        left: -12px;
        width: max-content;
        background: #EEEEEE;
        z-index: 5;
        li {
          font-size: 15px;
          line-height: 20px;
          color: $black;
          margin: 0 0 4px;
          &:last-child {
            margin-bottom: 0; } } } } }
  .btn {
    max-width: 73px;
    @media (max-width: 767px) {
      box-sizing: border-box;
      grid-area: btn; }
    &:after {
      width: 59px;
      height: 59px; } } }
