section.services {
  margin-bottom: 210px;
  @media (max-width: 767px) {
    margin-bottom: 70px; }
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-bottom: 55px;
    @media (max-width: 767px) {
      flex-direction: column;
      align-items: baseline;
      margin-bottom: 20px; }
    &:before {
      content: '';
      height: 100%;
      width: 1px;
      background: #39394f;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      @media (max-width: 767px) {
        content: none; } }
    .section-title {
      font-size: 45px;
      line-height: 60px;
      color: $black;
      margin: 22px 0;
      max-width: 375px;
      @media (max-width: 767px) {
        margin: 0 0 10px;
        max-width: none; }
      @media (max-width: 576px) {
        font-size: 23px;
        line-height: 28px; }
      span {
        font-weight: 800;
        @media (max-width: 576px) {
          display: block; } } }
    .text {
      max-width: 485px;
      font-size: 15px;
      line-height: 22px;
      color: $black;
      @media (max-width: 1200px) {
        max-width: calc(50% - 15px); }
      @media (max-width: 767px) {
        max-width: none; }
      @media (max-width: 576px) {
        font-size: 13px;
        line-height: 19px; }
      p {
        margin: 0; } } }
  .grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 1fr;
    @media (max-width: 840px) {
      grid-template-columns: repeat(3, 1fr); }
    @media (max-width: 767px) {
      grid-template-columns: 1fr 1fr; }
    @media (max-width: 576px) {
      grid-template-columns: 50% 50%; }
    .photo {
      grid-column-start: 1;
      grid-column-end: 4;
      grid-row-start: 1;
      grid-row-end: 3;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      position: relative;
      @media (max-width: 767px) {
        grid-column-end: 3;
        justify-content: flex-start; }
      img {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        z-index: -1; } }
    p {
      margin: 0;
      max-width: calc(66.6% + 1px);
      background: $main-color;
      color: #ffffff;
      box-sizing: border-box;
      padding: 12px 32px 12px 46px;
      font-weight: 800;
      font-size: 23px;
      line-height: 32px;
      text-transform: uppercase;
      @media (max-width: 970px) {
        max-width: calc(66.6% + 6px); }
      @media (max-width: 767px) {
        max-width: 420px;
        padding: 12px 16px; }
      @media (max-width: 576px) {
        max-width: 270px;
        font-size: 13px;
        line-height: 17px; } }
    .link {
      background: #E7E7E7;
      border: 1px solid #AEAEAE;
      box-sizing: border-box;
      height: 240px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      color: $black;
      font-weight: 800;
      font-size: 23px;
      line-height: 32px;
      text-transform: uppercase;
      text-align: center;
      position: relative;
      transition: transform .3s, background .3s;
      @media (max-width: 1365px) {
        font-size: 18px;
        line-height: 21px;
        height: 210px; }
      @media (max-width: 970px) {
        min-height: 180px;
        height: auto;
        padding: 15px; }
      @media (max-width: 576px) {
        min-height: 130px;
        font-size: 13px;
        line-height: 17px;
        padding: 10px; }
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 200px;
        height: 120px;
        background: url("../img/svg/mask.svg") center center;
        z-index: 0;
        @media (max-width: 576px) {
          width: 100%;
          height: 100%; } }
      &:hover {
        transform: scale(1.1);
        background: $main-color;
        color: #ffffff;
        z-index: 4;
        span {
          transform: scale(.9); } }
      &.hover {
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 2;
        grid-row-end: 3;
        @media (max-width: 767px) {
          grid-column-start: 2;
          grid-column-end: 3; } }
      span {
        position: relative;
        z-index: 1;
        padding: 5px 12px;
        transition: transform .3s; } } } }
