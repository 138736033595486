section.service-seo {
  display: flex;
  flex-direction: column;
  margin-bottom: 190px;
  @media (max-width: 767px) {
    margin-bottom: 75px; }
  .section-title {
    font-size: 30px;
    line-height: 37px;
    font-weight: 800;
    text-transform: uppercase;
    color: $black;
    margin: 0 0 36px; }
  .text {
    display: flex;
    flex-direction: column;
    margin-bottom: 80px;
    overflow-y: hidden;
    height: 157px;
    transition: height .3s;
    @media (max-width: 576px) {
      margin-bottom: 50px; }
    &>* {
      margin: 0 0 10px;
      &:last-child {
        margin-bottom: 0; } }
    p {
      font-size: 15px;
      line-height: 22px;
      color: $black; } }
  &>.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &:before {
      content: '';
      width: 100%;
      height: 1px;
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      top: 0;
      margin: auto;
      background: $black; } }
  .btn {
    padding: 0;
    width: 105px;
    text-align: center;
    justify-content: center;
    z-index: 1;
    @media (max-width: 576px) {
      width: 73px;
      font-size: 10px;
      line-height: 22px; }
    &:after {
      width: 105px;
      height: 105px;
      background: #ffffff;
      z-index: -1;
      @media (max-width: 576px) {
        width: 73px;
        height: 73px; } }
    &:before {
      content: none; } } }
