.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  box-sizing: border-box;
  @media (min-width: 576px) {
    max-width: 540px; }
  @media (min-width: 768px) {
    max-width: none; }
  @media (min-width: 1024px) {
    max-width: 960px; }
  @media (min-width: 1200px) {
    max-width: 1024px; }
  @media (min-width: 1366px) {
    max-width: 1200px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  box-sizing: border-box;
  .container {
    padding-right: 0;
    padding-left: 0; } }
