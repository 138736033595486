section.teams {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 150px;
  @media (max-width: 767px) {
    flex-direction: column;
    margin-bottom: 60px; }
  .user {
    display: flex;
    align-items: center;
    width: 50%;
    box-sizing: border-box;
    height: max-content;
    position: relative;
    margin-bottom: 80px;
    @media (max-width: 767px) {
      width: 100%; }
    &:last-child {
      margin-bottom: 150px; }
    @media (min-width: 768px) {
      &:nth-last-child(-n + 2) {
        margin-bottom: 0; }
      &:nth-child(odd) {
        &:after {
          content: '';
          width: 1px;
          height: 103.8%;
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          transition: left .3s, opacity .3s;
          left: 100%;
          z-index: 3;
          opacity: 1;
          background: #9E9E9E;
          transform: rotate(15.6deg);
          @media (max-width: 1365px) {
            height: 103.5%; } }
        &:before {
          content: '';
          width: 120px;
          height: calc(100% - 2px);
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          transition: right .3s, opacity .3s;
          right: 0;
          border-top: 1px solid #9E9E9E;
          opacity: 1;
          z-index: 1;
          background: #ffffff;
          transform: skew(-16deg, 0); }
        &.hover:not(.right) {
          &:after {
            left: 200%;
            opacity: 0; }
          &:before {
            right: -100%;
            opacity: 0; } }
        &.hover.right {
          &:after {
            left: 0;
            opacity: 0; }
          &:before {
            right: 100%;
            opacity: 0; } }
        &.active {
          &:after {
            z-index: -1; }
          .content {
            &:after {
              content: '';
              width: 20%;
              height: 50%;
              position: absolute;
              right: 100%;
              bottom: 0;
              background: #ffffff; } } } }
      &:nth-child(even) {
        &.active {
          .content {
            left: -100%;
            &:after {
              content: '';
              width: 24%;
              height: 50%;
              position: absolute;
              left: 100%;
              top: 0;
              background: #ffffff; } } }
        .preview {
          flex-direction: row-reverse;
          align-items: flex-end;
          border-width: 1px 1px 1px 0;
          padding-left: 0;
          padding-right: 20px;
          .person {
            @media (max-width: 970px) {
              display: flex;
              justify-content: flex-end; }
            .image {
              display: flex;
              justify-content: flex-end; }
            .name {
              left: auto;
              right: 0; } }
          .btn {
            margin-top: 0;
            margin-bottom: 170px;
            @media (max-width: 970px) {
              margin-bottom: 50px; } } }
        .content {
          border-width: 1px 0 1px 1px;
          padding: 0 15px 0 65px;
          direction: rtl;
          @media (max-width: 1200px) {
            padding: 5px 10px; }
          * {
            direction: ltr; } } } }
    &.active {
      .content {
        left: 100%;
        opacity: 1;
        @media (max-width: 767px) {
          position: relative;
          padding: 5px 10px;
          left: auto; } }
      .preview {
        @media (max-width: 767px) {
          display: none; }
        .btn {
          @media (max-width: 767px) {
            z-index: 5; } }
        &.bordered:after {
          opacity: 0; } } }
    .preview {
      width: 100%;
      padding-top: 10px;
      padding-left: 20px;
      box-sizing: border-box;
      display: flex;
      align-items: flex-start;
      position: relative;
      background: #ffffff;
      border: solid #9E9E9E;
      border-width: 1px 0 1px 1px;
      z-index: 2;
      @media (max-width: 767px) {
        border-width: 1px;
        flex-direction: row-reverse;
        justify-content: flex-end; }
      &.bordered {
        &:after {
          content: '';
          height: 1px;
          width: 16.15%;
          position: absolute;
          bottom: -1px;
          opacity: 1;
          transition: opacity .3s;
          right: 0;
          background: #ffffff;
          @media (max-width: 1365px) {
            width: 14.5%; }
          @media (max-width: 970px) {
            width: 11%; }
          @media (max-width: 767px) {
            content: none; } } }
      .person {
        max-width: 470px;
        width: 100%;
        @media (max-width: 767px) {
          display: flex;
          justify-content: flex-end; }
        .name {
          position: absolute;
          bottom: 0;
          left: 0;
          max-width: 470px;
          background: #36A38F;
          margin: 0;
          padding: 10px 50px;
          box-sizing: border-box;
          font-size: 30px;
          line-height: 37px;
          color: #ffffff;
          @media (max-width: 1365px) {
            padding: 5px 10px; }
          @media (max-width: 970px) {
            font-size: 21px;
            line-height: 24px; }
          @media (max-width: 767px) {
            left: auto;
            right: 0;
            font-size: 30px;
            line-height: 37px;
            width: 100%;
            max-width: none;
            padding-left: 25px; }
          @media (max-width: 480px) {
            font-size: 21px;
            line-height: 24px; }
          span {
            display: block;
            font-weight: 800; } }
        .image {
          max-height: 100%;
          width: 100%;
          @media (max-width: 970px) {
            width: 75%; }
          @media (max-width: 767px) {
            margin-right: 15px; }
          img {
            max-height: 100%;
            max-width: 100%;
            width: 100%;
            height: auto;
            display: block; } } }
      .btn {
        margin-top: 110px;
        padding-left: 45px;
        margin-right: 20px;
        position: relative;
        z-index: 5;
        @media (max-width: 970px) {
          padding-left: 15px;
          margin-top: 40px; }
        @media (max-width: 767px) {
          margin-top: 70px;
          padding-left: 25px; }
        @media (max-width: 480px) {
          padding-left: 15px;
          margin-top: 40px; }
        &:after {
          width: 125px;
          height: 125px;
          @media (max-width: 970px) {
            width: 75px;
            height: 75px; }
          @media (max-width: 767px) {
            width: 95px;
            height: 95px; }
          @media (max-width: 480px) {
            width: 75px;
            height: 75px; } }
        &:before {
          width: 46px;
          height: 46px;
          @media (max-width: 970px) {
            width: 23px;
            height: 23px; } } } }
    .content {
      background: #ffffff;
      width: 100%;
      position: absolute;
      z-index: 0;
      left: 0;
      opacity: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      overflow-y: scroll;
      padding: 0 65px 0 15px;
      box-sizing: border-box;
      transition: left .3s, opacity .3s;
      top: 0;
      bottom: 0;
      margin: auto;
      border: solid #9E9E9E;
      border-width: 1px 1px 1px 0;
      &::-webkit-scrollbar {
        width: 1px; }
      &::-webkit-scrollbar-thumb {
        background-color: #000000;
        border: 1px solid #555555; }
      @media (max-width: 1200px) {
        padding: 5px 10px; }
      @media (max-width: 767px) {
        height: 0;
        padding: 0 10px;
        transition: height .7s;
        bottom: auto;
        z-index: 3;
        opacity: 1;
        border-width: 1px; }
      .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: max-content;
        max-height: 100%; }
      .back {
        display: none;
        padding-left: 35px;
        align-self: flex-end;
        font-size: 12px;
        line-height: 14px;
        color: $black;
        background: transparent;
        border: none;
        position: relative;
        margin-bottom: 16px;
        &:before {
          content: '';
          width: 22px;
          height: 22px;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          background: url("../img/svg/right-arrow.svg") center center / 100% 100% no-repeat; }
        @media (max-width: 767px) {
          display: flex; } }
      .text {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        &>* {
          margin: 0 0 5px;
          &:last-child {
            margin-bottom: 0; } }
        p {
          font-size: 15px;
          line-height: 22px;
          color: $black;
          @media (max-width: 970px) {
            font-size: 12px;
            line-height: 16px; } } }
      .education {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 20px;
        .title {
          font-weight: 800;
          font-size: 23px;
          line-height: 26px;
          color: #36a38f;
          text-transform: uppercase;
          margin: 0 0 5px;
          @media (max-width: 970px) {
            font-size: 18px;
            line-height: 21px; } }
        ul {
          padding: 0;
          margin: 0;
          list-style: none;
          display: flex;
          flex-direction: column;
          li {
            padding-left: 25px;
            font-size: 15px;
            line-height: 22px;
            color: $black;
            margin: 0 0 10px;
            position: relative;
            @media (max-width: 970px) {
              font-size: 12px;
              line-height: 16px; }
            &:after {
              content: '';
              width: 10px;
              height: 10px;
              position: absolute;
              top: 7px;
              left: 0;
              background: #35688C; }
            &:last-child {
              margin-bottom: 0; } } } }
      .specialization {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 20px;
        .title {
          font-weight: 800;
          font-size: 23px;
          line-height: 26px;
          color: #36a38f;
          text-transform: uppercase;
          margin: 0 0 5px;
          @media (max-width: 970px) {
            font-size: 18px;
            line-height: 21px; } }
        ul {
          padding: 0;
          margin: 0;
          list-style: none;
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 40px;
          flex-direction: column;
          @media (max-width: 479px) {
            grid-template-columns: 1fr; }
          li {
            padding-left: 25px;
            font-size: 15px;
            line-height: 22px;
            color: $black;
            margin: 0 0 10px;
            position: relative;
            @media (max-width: 970px) {
              font-size: 12px;
              line-height: 16px; }
            &:after {
              content: '';
              width: 10px;
              height: 10px;
              position: absolute;
              top: 7px;
              left: 0;
              background: #35688C; }
            &:last-child {
              margin-bottom: 0; } } } } } } }
