.actual-news {
  display: flex;
  flex-direction: column;
  background: #F8F8F8;
  position: absolute;
  padding: 95px 20px 50px;
  @media (max-width: 1365px) {
    padding: 50px 10px 20px; }
  @media (max-width: 1200px) {
    width: 465px; }
  @media (max-width: 940px) {
    width: 420px;
    padding-top: 20px; }
  @media (max-width: 767px) {
    width: 100%;
    box-sizing: border-box; }
  @media (max-width: 480px) {
    padding: 35px 15px 45px; }
  &:after {
    content: '';
    width: 100vw;
    height: 100%;
    position: absolute;
    left: 100%;
    top: 0;
    background-color: #F8F8F8; }
  .title {
    font-weight: 800;
    font-size: 23px;
    line-height: 23px;
    text-transform: uppercase;
    color: $black;
    margin: 0 0 35px 25px;
    @media (max-width: 940px) {
      margin-bottom: 15px; }
    @media (max-width: 480px) {
      font-size: 18px;
      margin-bottom: 30px; } }
  .flex {
    display: flex;
    flex-direction: column;
    .post {
      display: flex;
      flex-direction: column;
      padding: 25px 25px 25px 20px;
      border-bottom: 1px solid #bababa;
      max-width: 510px;
      box-sizing: border-box;
      @media (max-width: 940px) {
        padding: 15px 15px 15px 10px; }
      .name {
        font-weight: 800;
        font-size: 15px;
        line-height: 20px;
        text-transform: uppercase;
        color: $main-color;
        text-decoration: none;
        margin: 0 0 5px;
        @media (max-width: 480px) {
          font-size: 13px;
          line-height: 17px; } }
      .info {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .date {
          font-size: 12px;
          line-height: 22px;
          color: #9e9e9e;
          margin: 0 9px 0 0;
          @media (max-width: 480px) {
            font-size: 11px; } }
        .views {
          padding-left: 20px;
          font-size: 12px;
          line-height: 22px;
          color: #9e9e9e;
          margin: 0;
          position: relative;
          @media (max-width: 480px) {
            font-size: 11px; }
          &:before {
            content: '';
            width: 14px;
            height: 14px;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            margin: auto;
            background: url("../img/svg/views.svg") center center / 100% 100% no-repeat; } } } } } }
