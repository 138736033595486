section.breadcrumbs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 35px;
  margin-bottom: 70px;
  @media (max-width: 767px) {
    margin-top: 0; }
  .list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0;
    margin: 0 15px 0 0;
    list-style: none;
    li {
      padding-right: 10px;
      margin-right: 5px;
      position: relative;
      font-size: 12px;
      line-height: 18px;
      @media (max-width: 576px) {
        font-size: 7px; }
      &:after {
        content: '/';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto; }
      &:last-child {
        padding-right: 0;
        margin: 0;
        &:after {
          content: none; } }
      a {
        color: $black;
        text-decoration: none;
        margin: 0; }
      span {
        color: #9e9e9e;
        margin: 0; } } }
  .back {
    font-size: 12px;
    line-height: 18px;
    color: $black;
    margin: 0;
    @media (max-width: 767px) {
      font-size: 0;
      width: 7px;
      height: 13px;
      transform: rotate(180deg);
      background: url("../img/svg/slider-arrow.svg") center center / 100% 100% no-repeat; } } }
