section.about-us {
  display: flex;
  flex-direction: column;
  margin-bottom: 150px;
  @media (max-width: 767px) {
    margin-bottom: 50px; }
  .top-block {
    display: flex;
    flex-direction: column;
    margin-bottom: 90px;
    @media (max-width: 576px) {
      margin-bottom: 30px; }
    .top-image {
      width: 100%;
      height: auto;
      margin-bottom: 85px;
      @media (max-width: 480px) {
        margin-bottom: 65px; } }
    .desc {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      @media (max-width: 920px) {
        flex-direction: column;
        align-items: center; }
      .image {
        position: relative;
        width: calc((100% - 60px) / 2);
        height: auto;
        @media (max-width: 1200px) {
          width: calc((100% - 20px) / 2); }
        @media (max-width: 920px) {
          width: 100%;
          max-width: 520px;
          margin-bottom: 25px; }
        &:before {
          content: '';
          height: calc(100% + 70px);
          top: -70px;
          left: -55px;
          width: 100%;
          position: absolute;
          background: rgba(248, 248, 248, 0.95);
          z-index: -1;
          @media (max-width: 480px) {
            top: -40px;
            left: -15px; } }
        &:after {
          content: '';
          width: 220px;
          height: 277px;
          background: url("../img/svg/mask.svg") center center / cover no-repeat;
          position: absolute;
          top: -70px;
          left: -55px;
          z-index: -1;
          @media (max-width: 480px) {
            top: -40px;
            left: -15px; } } }
      .right-side {
        width: calc((100% - 60px) / 2);
        display: flex;
        flex-direction: column;
        @media (max-width: 1200px) {
          width: calc((100% - 20px) / 2); }
        @media (max-width: 920px) {
          width: 100%; }
        .title {
          margin: 42px 0 30px;
          font-weight: 800;
          font-size: 30px;
          line-height: 37px;
          text-transform: uppercase;
          color: $black;
          @media (max-width: 576px) {
            margin-top: 0; }
          @media (max-width: 480px) {
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 12px; } }
        .text {
          display: flex;
          flex-direction: column;
          margin: 0;
          &>* {
            margin: 0 0 5px;
            &:last-child {
              margin-bottom: 0; } }
          p {
            font-size: 15px;
            line-height: 22px;
            color: $black;
            @media (max-width: 480px) {
              font-size: 13px;
              line-height: 19px; } } } } } }
  &>.title {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #35688C;
    padding: 43px;
    box-sizing: border-box;
    text-align: center;
    width: 100vw;
    font-weight: 800;
    font-size: 23px;
    line-height: 22px;
    color: #ffffff;
    text-transform: uppercase;
    margin: 0 0 90px;
    @media (max-width: 767px) {
      padding: 43px 15px; }
    @media (max-width: 576px) {
      margin-bottom: 25px;
      padding: 30px 5px; }
    @media (max-width: 480px) {
      font-size: 13px;
      line-height: 17px; }
    &>p {
      margin: 0; } }
  .seo-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    .text {
      display: flex;
      flex-direction: column;
      margin-bottom: 75px;
      &>* {
        margin: 0 0 30px;
        &:last-child {
          margin-bottom: 0; } }
      p {
        font-size: 15px;
        line-height: 22px;
        color: $black;
        @media (max-width: 480px) {
          font-size: 13px;
          line-height: 19px; } } }
    .btn {
      margin-bottom: 45px;
      padding-left: 48px;
      max-width: 70px;
      font-size: 15px;
      @media (max-width: 576px) {
        padding-left: 26px;
        font-size: 12px;
        margin-bottom: 23px; }
      &:after {
        width: 125px;
        height: 125px;
        @media (max-width: 576px) {
          width: 73px;
          height: 73px; } }
      &:before {
        width: 46px;
        height: 46px;
        @media (max-width: 576px) {
          width: 23px;
          height: 23px; } } } } }

