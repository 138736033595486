section.news {
  display: flex;
  flex-direction: column;
  margin-bottom: 195px;
  @media (max-width: 767px) {
    margin-bottom: 50px; }
  .section-title {
    font-weight: 800;
    font-size: 45px;
    line-height: 60px;
    text-transform: uppercase;
    color: $black;
    margin: 0 0 70px; }
  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    .actual-news {
      position: absolute;
      top: -120px;
      right: 0;
      @media (max-width: 940px) {
        top: -180px; }
      @media (max-width: 767px) {
        position: relative;
        top: 0;
        order: 1;
        width: calc(100% + 20px);
        margin-right: -15px; } }
    .grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-auto-rows: auto;
      grid-column-gap: 100px;
      grid-row-gap: 30px;
      margin-bottom: 105px;
      @media (max-width: 767px) {
        grid-template-columns: 1fr; }
      .elem:first-child {
        grid-column-start: 1;
        grid-column-end: 3;
        width: calc((100% - 100px) / 2);
        @media (max-width: 767px) {
          grid-column-end: 2;
          width: 100%; } }
      .elem {
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
        .image {
          margin-right: 70px;
          position: relative;
          @media (max-width: 767px) {
            margin-right: 12px; } }
        .text {
          display: flex;
          flex-direction: column;
          margin-top: -60px;
          background: rgba(248, 248, 248, 0.95);
          padding: 30px 24px 20px 45px;
          box-sizing: border-box;
          margin-left: 25px;
          position: relative;
          z-index: 2;
          @media (max-width: 940px) {
            padding: 15px 12px 10px 25px;
            margin-left: 10px;
            margin-right: 10px; }
          @media (max-width: 767px) {
            margin-left: 12px;
            margin-right: 0; }
          @media (max-width: 480px) {
            padding: 25px 5px 20px 15px; }
          .title {
            font-size: 23px;
            line-height: 32px;
            text-transform: uppercase;
            color: $black;
            display: flex;
            flex-direction: column;
            margin: 0 0 10px;
            text-decoration: none;
            @media (max-width: 970px) {
              font-size: 21px;
              line-height: 28px; }
            @media (max-width: 830px) {
              font-size: 18px;
              line-height: 24px; }
            @media (max-width: 767px) {
              font-size: 23px;
              line-height: 32px; }
            @media (max-width: 480px) {
              font-size: 18px;
              line-height: 22px;
              margin-bottom: 5px; }
            span {
              font-weight: 800;
              display: block; } }
          .desc {
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;
            max-height: 70px;
            overflow: hidden;
            position: relative;
            @media (max-width: 480px) {
              margin-bottom: 5px; }
            &:before {
              content: '';
              width: 100%;
              height: 100%;
              user-select: none;
              pointer-events: none;
              position: absolute;
              bottom: 0;
              left: 0;
              background: linear-gradient(180deg,rgba(50, 50, 50, 0) 0%,#f8f8f8 100%); }
            &>* {
              margin: 0 0 5px;
              &:last-child {
                margin-bottom: 0; } }
            p {
              font-size: 15px;
              line-height: 22px;
              color: $black;
              @media (max-width: 480px) {
                font-size: 13px;
                line-height: 19px; } } }
          .user-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .left-side {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              .date {
                font-size: 12px;
                line-height: 22px;
                color: #9e9e9e;
                margin: 0 9px 0 0;
                @media (max-width: 480px) {
                  font-size: 11px; } }
              .views {
                padding-left: 20px;
                position: relative;
                font-size: 12px;
                line-height: 22px;
                color: #9e9e9e;
                margin: 0;
                @media (max-width: 480px) {
                  font-size: 11px; }
                &:before {
                  content: '';
                  width: 14px;
                  height: 14px;
                  position: absolute;
                  top: 0;
                  left: 0;
                  bottom: 0;
                  margin: auto;
                  background: url("../img/svg/views.svg") center center / 100% 100% no-repeat; } } }
            a {
              font-size: 15px;
              line-height: 18px;
              color: $black;
              margin: 0;
              padding-left: 35px;
              position: relative;
              text-decoration: none;
              @media (max-width: 480px) {
                font-size: 12px; }
              &:before {
                content: '';
                width: 25px;
                height: 25px;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                margin: auto;
                background: url("../img/svg/right-arrow.svg") center center / 100% 100% no-repeat; } } } } } }
    &>.btn {
      margin: 0 auto;
      padding-left: 45px;
      max-width: 80px;
      font-size: 15px;
      @media (max-width: 767px) {
        margin-bottom: 100px; }
      @media (max-width: 480px) {
        padding-left: 26px;
        font-size: 12px;
        margin-bottom: 50px; }
      &:after {
        width: 125px;
        height: 125px;
        @media (max-width: 480px) {
          width: 73px;
          height: 73px; } }
      &:before {
        width: 46px;
        height: 46px;
        @media (max-width: 480px) {
          width: 23px;
          height: 23px; } } } } }
